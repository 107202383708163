.App {
  text-align: center;
}

.App-header a {
  padding: 10px;
  text-decoration: None;
  color: black;
}

.AppSubHeader {
  font-style: italic;
}

.AppPosts {
  max-width: 800px;
  margin: auto;
}

.AppPost {
  text-align: left;
  padding: 0px 20px 0px 20px;
}

.AppPostHeader {
  display: flex;
  align-items: baseline;
}

.AppPostHeader h2 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.AppPostHeader h2 a {
  color: black;
}
.AppPostHeader h2 a:hover {
  color: red;
}

.AppPostEarning {
  color: green;
  font-weight: bold;
  margin-left: 20px;
}

.AppPostGuide {
  color: blue;
  font-style: italic;
  text-decoration: none;
  font-weight: bold;
  margin-left: auto;
}

.AppPostGuide a {
  text-decoration: none;
}

.AppPostGuide a:hover {

  text-shadow: 0 0 1px;
}

footer {
  text-align: left;
  max-width: 800px;
  margin: auto;
}

footer h2 {
  font-style: italic;
  margin-top: 20px;
  margin-bottom: 0px;
  padding-left: 20px;
}

.col {
  padding-left: 20px;
  padding-right: 20px;
}

.AppLinks ul {
  list-style-type: none;
  padding-left: 0px;
}

.AppLinks {
  display: flex;
  justify-content: space-between;
}

footer a {
  color: grey;
}

footer a:hover {
  text-shadow: 0 0 1px;
}
